
























































import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { FormBlock } from '@/app/components';

export default defineComponent({
    name: 'UserPreferences',
    components: {
        ValidationObserver,
        FormBlock,
    },
    props: {
        defaultPreferences: {
            type: Object,
            required: true,
        },
        loading: {
            type: Boolean,
        },
    },

    setup() {
        // Modify event title to a readable label
        const getEventTitle = (event: string) => {
            switch (event) {
                // workflow
                case 'workflow.service.completed':
                    return 'When a workflow is successfully executed';
                case 'workflow.service.failed':
                    return 'When a workflow execution fails';
                // dcj
                case 'dcj.service.completed':
                    return 'When a data collection task is successfully executed';
                case 'dcj.service.failed':
                    return 'When a data collection task execution fails';
                // model suggestions
                case 'concept.created':
                case 'concept.approved':
                case 'concept.rejected':
                    return `When a suggestion is  ${event.split('.').slice(1).join(' ')}`;
                default:
                    break;
            }

            return '';
        };

        return { getEventTitle };
    },
});
